body {
  margin: 0;
  display: flex;
  flex-direction: column; 
  background-color: #E5F2E1;
  color: black;
  padding-top: 20px; 
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile {
  width: 80%; 
  max-width: 400px; 
  margin: 20px; 
  text-align: left;
}

.profile-header {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  body {
      padding: 10px; 
  }

  .profile {
      width: 100%; 
  }
}
